'use strict';

var checkboxes1 = 0;
var checkboxes2 = 0;
var checkboxes3 = 0;
var checkboxesItems1 = [];
var checkboxesItems2 = [];
var checkboxesItems3 = [];

function refinementFilterAjaxCall(e) {
    e.preventDefault();
    e.stopPropagation();
    checkboxesItems1 = [];
    checkboxesItems2 = [];
    checkboxesItems3 = [];
    $.spinner().start();
    $(this).trigger('search:filter', e);
    var radiotype = $(this).data('radiotype');
    var refinementID = $(this).data('refinementid');
    var refinementValue = $(this).data('refinementvalue');
    var url = $(this).data('href');
    if (radiotype) {
        var urlArray = url.split('?');
        var refinementNumber;
        var refValue;
        urlArray[1].split('&').forEach(function (ele) {
            if (ele.includes(refinementID) && ele.includes('prefn')) {
                refinementNumber = ele[5];
            }
        });
        urlArray[1].split('&').forEach(function (ele) {
            if (ele.includes('prefv' + refinementNumber)) {
                refValue = 'prefv' + refinementNumber + '=' + encodeURIComponent(refinementValue);
                url = url.replace(ele, refValue);
            }
        });
    }
    window.searchRefinementBannerUrl = url;
    var topBlockID = $(this).closest('.card-block').attr('id');
    $.ajax({
        url: url,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: $(this).data('href'),
            refinementsConfigured: $('.refinements').data('refinementsconfigured')
        },
        method: 'GET',
        success: function (response) {
            $('.refinements').empty().html($(response).html());
            $.spinner().stop();
            $('.card-block#' + topBlockID).find('.card-header').addClass('current');
            $('.card-block#' + topBlockID).find('.card-body').addClass('active');

            checkboxes1 = $('.card-block#refinement-rv-type').find('input:checkbox:checked').length;
            $('#refinement-rv-type ul li').each(function () {
                var arr1 = $(this).find('input:checkbox:checked').attr('data-displayname');
                if (arr1 !== undefined) {
                    checkboxesItems1.push(arr1);
                }
            });

            if (checkboxes1 > 0) {
                $('#refinement-rv-type-text').empty().append(checkboxesItems1[0]);
                var checkcount1 = checkboxes1 - 1;
                if (checkcount1 > 0) {
                    $('#refinement-rv-type-1').empty().append('+' + checkcount1);
                } else {
                    $('#refinement-rv-type-1').empty().append('');
                }
            }

            checkboxes2 = $('.card-block#refinement-sleeps').find('input:checkbox:checked').length;
            $('#refinement-sleeps ul li').each(function () {
                var arr2 = $(this).find('input:checkbox:checked').attr('data-displayname');
                if (arr2 !== undefined) {
                    checkboxesItems2.push(arr2);
                }
            });

            if (checkboxes2 > 0) {
                $('#refinement-sleeps-text').empty().append(checkboxesItems2[0]);
                var checkcount2 = checkboxes2 - 1;
                if (checkcount2 > 0) {
                    $('#refinement-sleeps-1').empty().append('+' + checkcount2);
                } else {
                    $('#refinement-sleeps-1').empty().append('');
                }
            }

            checkboxes3 = $('.card-block#refinement-max-price').find('input:radio:checked').length;
            $('#refinement-max-price ul li').each(function () {
                var arr3 = $(this).find('input:radio:checked').attr('data-displayname');
                if (arr3 !== undefined) {
                    checkboxesItems3.push(arr3);
                }
            });

            if (checkboxes3 > 0) {
                $('#refinement-max-price-text').empty().append(checkboxesItems3[0]);
                     // $('#refinement-max-price-1').empty().append("+" + checkboxes3);
            } else {
                $('#refinement-max-price-1').empty().append('');
            }
            $(document).trigger('refinementBanner:update');
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = {
    refinementBannerFilter: function () {
        // Handle refinement value selection
        $('body').on('click', '.swatch-square-block', refinementFilterAjaxCall);
        $('.refinement-bar').on('change', '.slider-control', refinementFilterAjaxCall);
        $('.refinement-bar').on('change', '.attribute-slider-control', refinementFilterAjaxCall);
    },
    searchRefinements: function () {
        $('body').on('click', '.refinements-search', function () {
            var url = window.searchRefinementBannerUrl;
            var url = url ? url.replace('ShowBannerRefinment', 'Show') : $(this).data('url');
            window.location.href = url;
        });
    },
    RefinementsList: function () {
        $('body').on('click', '.card-header', function () {
            if ($(this).hasClass('current')) {
                $('.card-header').removeClass('current');
                $(this).next('.card-body').removeClass('active');
            } else {
                $('.card-body').removeClass('active');
                $('.card-header').removeClass('current');
                $(this).addClass('current');
                $(this).next('.card-body').addClass('active');
            }
        });
        $('body').click(function (e) {
            if (!$(e.target).closest('.card-header').length) {
                $('.card-body').removeClass('active');
                $('.card-header').removeClass('current');
            }
        });
        $('body').on('click', '.card-body', function (e) {
            e.stopPropagation();
        });
    }
};
